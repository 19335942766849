import React from 'react'
import { allSkuProductsNonUniq } from '../../utils/products'

export default function ProductsPage() {
  const products = allSkuProductsNonUniq.map((product) => {
    return {
      ...product,
      image: product.image ? product.image.props.src : null,
      variants: product.variants
        ? product.variants.map((variant) => {
            return {
              ...variant,
              image: variant.image ? variant.image.props.src : null,
            }
          })
        : null,
      images: product.images
        ? product.images.map((image) => {
            return image.props.src
          })
        : null,
      bundledImages: product.bundledImages
        ? product.bundledImages.map((image) => {
            return image.props.src
          })
        : null,
    }
  })

  return (
    <div style={{ padding: 20 }}>
      <button
        onClick={() => {
          const element = document.getElementById('products')
          window.navigator.clipboard.writeText(element.innerHTML)
        }}
      >
        Copy
      </button>
      <pre
        id="products"
        style={{
          padding: 20,
          border: '1px solid #ccc',
          backgroundColor: '#eee',
          maxHeight: 500,
          overflow: 'auto',
        }}
      >
        {JSON.stringify(products, null, 2)}
      </pre>
    </div>
  )
}
